<template>
  <div id="app">

    <div class="container">

      <!-- HEADER -->
      <div class="row mt-5">
        <div class="col text-center">
          <div class="d-flex flex-row justify-content-center align-items-center">
            <div style="">
              <router-link to="/">
                <img src="/images/logo_header.png" style="max-width: 100%;"/>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- HEADER (END) -->

      <!-- CONTENT -->
      <router-view />
      <!-- CONTENT (END) -->

      <!-- FOOTER -->
      <footer class="">
        <div>
          <div class="row">
            <div class="col">
              <div class="text-center documents">
                <img
                  src="/images/frankie_flu.svg"
                  height="100px"
                  class="mb-3 mt-4"
                />
                <div style="font-size: large">
                  Read our
                  <a href="https://well.co.uk/about-us/policies/privacy" target="_blank"
                    >privacy policy</a
                  >.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="text-center">
                <a href="mailto:hello@well.co.uk"
                  >hello@well.co.uk</a
                >
              </div>
            </div>
          </div>
        </div>
      </footer>
      <!-- FOOTER (END) -->
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  methods: {},
};
</script>
